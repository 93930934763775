const defaultDigitsAmount = 2;

interface PriceObject {
    grossAmount: number
    currency: {
        code: string,
        name: string,
        symbol: string,
    }
}

export default function useMoneyFormat(value: number | PriceObject | null, symbol = '€'): string {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }
    if (typeof value === 'number') {
        return `${(Math.round(value * 100) / 100)
            .toFixed(defaultDigitsAmount)
            .replace('.', ',')
            .replace(/\d(?=(\d{3})+,)/g, '$&.')} ${symbol}`;
    }
    if (!value) {
        return `0 ${symbol}`;
    }

    return `${(Math.round(value.grossAmount) / 100)
        .toFixed(defaultDigitsAmount)
        .replace('.', ',')
        .replace(/\d(?=(\d{3})+,)/g, '$&.')} ${value.currency.symbol}`;
}
